import { Dropdown, Table, Form, Button, Badge, Modal, InputGroup } from "react-bootstrap";
import { CSVLink } from "react-csv";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  ExchangeFunds,
  ExcelIcon,
  PDFIcon,
  DeleteIcon,
  EditIcon,
  RecieptIcon,
  DropdownCloseIcon,
  DropdownEyeIcon,
  MoneyDollarIcon,
  CoinIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState, useMemo } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useCurrencies,
  useIsAdmin,
  useIsCashier,
  useIsOperations,
  useLocalStorage,
  useQueryParams,
  useReconcilation,
  useScrollTop,
} from "../../utils/hooks";
import { services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  defaultSelectValue,
  formatDate,
  paginationOptions,
  reactSelectTheme,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import { useAuth } from "../../hooks/useAuth";
import { isEmpty, uniqBy } from "lodash";
import NoTableItem from "../utils/NoTableItem";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { toast } from "react-toastify";
import ConfirmDialog from "../ConfirmDialogue";
import ReconcilationCheckbox from "../utils/ReconcilationCheckbox";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik, FieldArray, FormikProvider } from "formik";
import CreatableSelect from "react-select/creatable";
import JobItemsModal from "./JobItems";
import MileStoneModal from "./MileStoneModal";
import ExpensesSelector from "../utils/ExpensesSelector";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import NewRequisitionModal from "../Requisition/NewRequisition";
import { UnAuthorized } from "../utils/UnAuthorized";
import CustomerSelector from "../utils/CustomerSelector";
import { IsPrivileged } from "../DisplayChildElement";
import { useStoreState } from "easy-peasy";

function Attachments({ files = [], setFiles, milestone }) {
  //  const [files, setFiles] = useState([]);
  const { backendUrl } = useAuth();
  return (
    <div>
      <div>
        {files?.map((el, index) => (
          <div key={index} className="d-flex align-items-center">
            {el?.fileId ? (
              <a
                href={`${backendUrl}/api/job/view-certificate/${el.systemFilename}`}
                target="_blank"
                rel="noreferrer"
                className="text-primary"
              >
                {el?.name}
              </a>
            ) : (
              <span>{el?.name}</span>
            )}

            <Button
              variant=""
              size="sm"
              onClick={e => setFiles(files.filter((el, i) => i !== index))}
            >
              <DropdownCloseIcon width={16} />
            </Button>
            <hr className="m-0" />
          </div>
        ))}
      </div>

      <Form.Label className="btn btn-light-blue">
        <span>Add Files </span>
        <Form.Control
          type="file"
          className="d-none"
          multiple
          onChange={e => setFiles([...files, ...e.target.files])}
        />
      </Form.Label>
    </div>
  );
}

export default function AllJobs({ hasReconcilation = false }) {
  useScrollTop();
  const { deploymentCurrencies: currenciesOptions } = useCurrencies();
  const initialFilterParams = {
    jobNumber: "",
    title: "",
    branch: "",
    /*  startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"), */

    withBudget: true,
  };

  const { backendUrl, user: authUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [selectedJob, setSelectedJob] = useState();
  const [selectedJobForMileStone, setSelectedJobForMileStone] = useState();
  const [selectedJobForItems, setSelectedJobForItems] = useState();
  const [excelData, setExcelData] = useState([]);
  const isAdmin = useIsAdmin();
  const isCashier = useIsCashier();
  const navigate = useNavigate();
  const isOperations = useIsOperations();
  const [showExpensePopover, setShowExpensePopover] = useState(false);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [selectedJobForBudget, setSelectedJobForBudget] = useState();
  const CSVLinkRef = useRef(null);
  const generalSettings = useStoreState(state => state.generalSettings);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(excelData, CSVLinkRef);

  const {
    addToReconciledList,
    isReconciled,
    PostReconciledBtn,
    setUpReconcilations,
    postReconcilationMutation,
  } = useReconcilation({
    refetch: () => refetch(),
  });

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchJobs = async queryParams => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/job?&${queryString.stringify(queryParams)}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.milestones = data?.milestones.map(el => ({
      ...el,
      label: el.milestone,
      value: el.milestone,
    }));

    data.acquisitions = data.acquisitions.map(el => ({
      value: el.Job_Acquisition_Source,
      label: el.Job_Acquisition_Source,
    }));

    data.progress = data.progress.map(el => ({
      value: el.Job_Progress_Status,
      label: el.Job_Progress_Status,
    }));
    return data;
  };

  const {
    data = { count: 0, jobs: [], milestones: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(["JOBS", queryParams], () => fetchJobs(queryParams), {
    keepPreviousData: true,
  });

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/job?${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;
    exData = exData.data.jobs.map(row => ({
      ...row,
      dateLog: format(new Date(row.dateLog), "dd-MMM-yyyy hh:mm:ss a"),
    }));

    exData = exData.map(d => [
      String(d.jobNumber),
      d.title,
      d.description,
      customerFullName(d.customer),
      d.status,
      d?.jobBudget && d?.budgetRequestid
        ? currency(d?.jobBudget.amount, {
            symbol: currenciesOptions.find(
              curr =>
                curr.cc ===
                (d?.jobBudget?.currency
                  ? d?.jobBudget?.currency
                  : generalSettings?.prevailingCurrency)
            )?.symbol,
          }).format()
        : "...",
      currency(d.jobCost, {
        symbol: currenciesOptions.find(
          curr => curr.cc === (d?.currency ? d?.currency : generalSettings?.prevailingCurrency)
        )?.symbol,
      }).format(),
      d.dateLog,
      d.username,
      d.Job_Acquisition_Source,
      d.Job_Progress_Status,
      d.Contract_Award_Date ? formatDate(d.Contract_Award_Date) : "...",
      d.Expected_Completion_Date ? formatDate(d.Expected_Completion_Date) : "...",
    ]);

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";

    exData = [
      [company],
      ["Jobs/Contract"],
      [date],
      [""],
      [
        "Job/Contract Number",
        "Title",
        "Description",
        "Customer/Client",
        "Status",
        "Budget",
        "Cost",
        "Date",
        "Username",
        "Acquisition Source",
        "Progress Status",
        "Contract Award Date",
        "Expected Completion Date",
      ],
      ...exData,
      [""],
      [""],
      [""],
    ];
    // console.log(exData);
    setExcelData(exData);
  };

  const handleFilterParamsChange = e => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = e => {
    setFilterParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = date => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };
  const handleSelectedExpense = ({ AccountID, Description }) => {
    setFilterParams({
      ...filterParams,
      jobNumber: AccountID,
      title: Description,
    });
    setShowExpensePopover(false);
  };

  const deleteExpense = async payload => {
    let response = await fetch(`${backendUrl}/api/job/delete`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteMutation = useMutation(payload => deleteExpense(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      refetch();
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const handleDeleteJob = async job => {
    if (
      await ConfirmDialog({
        title: "Delete",
        description: "Are you sure you want to delete",
      })
    ) {
      deleteMutation.mutate({ job });
    }
  };

  const handleEditJob = async job => {
    //  Get Job Items
    try {
      setIsLoading(true);

      let response = await fetch(
        `${backendUrl}/api/job/job-items/${job.jobNumber}?jobNumber=${job.jobNumber}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error();
      } else {
        const {
          data: { jobItems, customer },
        } = await response.json();

        setIsLoading(false);
        navigate("/operations/jobs/edit", {
          state: {
            job,
            jobItems: jobItems.map(el => ({
              ...el,
              customer,
            })),
          },
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to get Jobs Items, Try again");
      setIsLoading(false);
    }
  };

  const prepareInvoice = async job => {
    navigate("/operations/jobs/prepare", {
      state: {
        job,
      },
    });
  };

  const removeCertificate = async job => {
    //  Get Job Items
    if (
      !(await ConfirmDialog({
        title: "Remove Certificate",
        description: "Are you sure you want to remove certificate",
      }))
    ) {
      return;
    }
    try {
      setIsLoading(true);
      let response = await fetch(`${backendUrl}/api/job/remove-certificate`, {
        method: "POST",
        body: JSON.stringify({ ...job }),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      });

      // console.log(response);

      if (!response.ok) {
        response = await response.json();
        throw new Error();
      } else {
        toast.success("Certificate Removed");
        refetch();
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable Delete, Try again");
    } finally {
      setIsLoading(false);
    }
  };

  const showJobItems = job => {};

  const handleSelectedCustomer = customer => {
    setFilterParams({
      ...filterParams,
      Cust_ID: customer.Cust_ID,
      customerName: customerFullName(customer),
    });
    setShowItemsPopover(false);
  };

  if (authUser.AccessLavel === "Low" && isOperations) {
    return <UnAuthorized />;
  }
  return (
    <IsPrivileged roleName={["Operations"]}>
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button variant="white" onClick={() => setShowFilter(!showFilter)}>
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    <Form.Group className="mb-2-5">
                      <Form.Label>Job/Contract Number</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="jobNumber"
                          placeholder="Enter Job/Contract Number"
                          value={filterParams?.jobNumber}
                          onChange={e => handleFilterParamsChange(e)}
                        />
                        <Popover
                          isOpen={showExpensePopover}
                          onClickOutside={() => setShowExpensePopover(false)}
                          content={() => (
                            <ExpensesSelector
                              handleSelectedExpense={handleSelectedExpense}
                              usage="chart of accounts"
                              DetailType="JOB"
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() => setShowExpensePopover(!showExpensePopover)}
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        name="title"
                        value={filterParams?.title}
                        placeholder="Enter Title"
                        onChange={e => handleFilterParamsChange(e)}
                      />
                    </Form.Group>

                    <hr className="my-3" />

                    <Form.Group className="mb-2-5">
                      <Form.Label>Customer/Client ID</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="Cust_ID"
                          placeholder="Enter ID"
                          value={filterParams?.Cust_ID}
                          onChange={e => handleFilterParamsChange(e)}
                        />
                        <Popover
                          isOpen={showItemsPopover}
                          onClickOutside={() => setShowItemsPopover(false)}
                          content={() => (
                            <CustomerSelector
                              handleSelectedCustomer={handleSelectedCustomer}
                              setShowCustomerSelector={setShowItemsPopover}
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text onClick={() => setShowItemsPopover(!showItemsPopover)}>
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Customer/Client Name</Form.Label>
                      <Form.Control
                        name="customerName"
                        value={filterParams?.customerName}
                        placeholder="Enter Name"
                        onChange={e => handleFilterParamsChange(e)}
                      />
                    </Form.Group>

                    <hr className="mt-3 mb-4" />

                    <Form.Group className="mb-2-5">
                      <Form.Label>Acquisition Source</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="Select"
                        options={data?.acquisitions}
                        onChange={({ value = "" }) =>
                          setFilterParams({
                            ...filterParams,
                            Job_Acquisition_Source: value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Progress Status</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="Select"
                        options={data?.progress}
                        onChange={({ value = "" }) =>
                          setFilterParams({
                            ...filterParams,
                            Job_Progress_Status: value,
                          })
                        }
                      />
                    </Form.Group>

                    <hr className="mt-3 mb-4" />

                    <Form.Group className="">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="auto"
                          value={
                            filterParams.startDate && filterParams.endDate
                              ? [
                                  parse(filterParams.startDate, "yyyy-MM-dd", new Date()),
                                  parse(filterParams.endDate, "yyyy-MM-dd", new Date()),
                                ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={date => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button onClick={() => setShowFilter(!showFilter)} className="btn filter">
                      <FilterTwoIcon />
                    </button>
                  )}
                  Jobs / Contract
                  <button title="Refresh" onClick={() => refetch()} className="btn text-primary">
                    <CachedIcon />
                  </button>
                </h1>
                <div className="actions mr-5">
                  <CSVLink
                    className="btn print d-none"
                    filename={`Jobs / Contract (${format(
                      new Date(),
                      "dd-MMM-yyyy hh:mm:ss a"
                    )}).csv`}
                    data={excelData}
                    ref={CSVLinkRef}
                  />

                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn print"
                      disabled={isfetchingExcel}
                      bsPrefix=""
                    >
                      <ExportIcon color="#008000" />
                      Export
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item as="div" onClick={onDownloadExcelData} className="p-cursor">
                        Excel <ExcelIcon color="#008000" />
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/job/pdf/job?${queryString.stringify(rest)}`}
                          target="blank"
                        >
                          PDF <PDFIcon color="#ff0000" />
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Link
                    to={`/requisition/request?page=1&limit=40&q=&status=&type=Pending Service Invoice`}
                    className="btn  btn-light-blue"
                  >
                    Approvals
                  </Link>
                  <Link to="/operations/jobs/create" className="btn  btn-primary">
                    Create New
                  </Link>
                </div>
              </header>

              <div className="px-md-4">
                <Table responsive borderless striped className="product-table">
                  <thead>
                    <tr>
                      <th />
                      <th>Job/Contract Number</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Customer/Client</th>
                      <th>Status</th>
                      <th>Budget</th>
                      {isAdmin || isCashier ? <th>Cost</th> : null}
                      <th>Entry Date</th>
                      <th>Username</th>
                      <th>Acquisition Source</th>
                      <th>Progress Status</th>
                      <th>Contract Award Date</th>
                      <th>Expected Completion Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.jobs.map((el, index) => (
                      <tr
                        key={index}
                        onClick={e =>
                          e.detail === 2 && navigate(`details/${encodeURIComponent(el.jobNumber)}`)
                        }
                      >
                        <td>
                          {el?.transID && (
                            <Dropdown style={{ margin: 0 }} alignRight drop="end">
                              <Dropdown.Toggle
                                variant=""
                                className="bg-white border-0"
                                bsPrefix="print more"
                              >
                                <DotsVerticalIcon />
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                className="dropdown-with-icons"
                                popperConfig={{
                                  strategy: "fixed",
                                }}
                                renderOnMount
                              >
                                {el.milestones ? (
                                  <Dropdown.Item as="button" onClick={() => prepareInvoice(el)}>
                                    <RecieptIcon />
                                    Prepare Invoice
                                  </Dropdown.Item>
                                ) : null}

                                <Dropdown.Item as="div">
                                  <Link to={`details/${encodeURIComponent(el.jobNumber)}`}>
                                    <RecieptIcon />
                                    Details
                                  </Link>
                                </Dropdown.Item>

                                {isAdmin && (
                                  <Dropdown.Item as="div">
                                    <Link
                                      to={`/reports/job-income-statement?page=1&limit=100&Description=${encodeURIComponent(
                                        el.title
                                      )}&AccountID=${encodeURIComponent(el.jobNumber)}`}
                                    >
                                      <CoinIcon /> Job/Contract Report
                                    </Link>
                                  </Dropdown.Item>
                                )}

                                <Dropdown.Item as="button" onClick={() => handleEditJob(el)}>
                                  <EditIcon />
                                  Edit
                                </Dropdown.Item>

                                <Dropdown.Item
                                  as="button"
                                  onClick={() => setSelectedJobForItems(el)}
                                >
                                  <DropdownEyeIcon />
                                  Invoices
                                </Dropdown.Item>

                                <Dropdown.Item as="button" onClick={() => setSelectedJob(el)}>
                                  <RecieptIcon />
                                  Upload Completion Certificate
                                </Dropdown.Item>

                                {el.hasMilestone && (
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => setSelectedJobForMileStone(el)}
                                  >
                                    <DropdownEyeIcon />
                                    Milestones
                                  </Dropdown.Item>
                                )}
                                <Dropdown.Item as="div">
                                  <Link
                                    to={`stages?jobNumber=${el.jobNumber}&title=${el.title}`}
                                    state={{ job: el, isOperation: true }}
                                  >
                                    <DropdownEyeIcon />
                                    QHSE Documented Information
                                  </Link>
                                </Dropdown.Item>

                                {el.budgetRequestid ? (
                                  <Dropdown.Item as="div">
                                    <Link to={`/requisition/request/preview/${el.budgetRequestid}`}>
                                      <DropdownEyeIcon /> View/Edit Budget
                                    </Link>
                                  </Dropdown.Item>
                                ) : (
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => setSelectedJobForBudget(el)}
                                  >
                                    <MoneyDollarIcon /> Create Budget
                                  </Dropdown.Item>
                                )}

                                {isAdmin && (
                                  <Dropdown.Item
                                    as="button"
                                    className="text-danger"
                                    onClick={() => handleDeleteJob(el)}
                                  >
                                    <DeleteIcon color="currentColor" /> Delete
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </td>
                        <td>{el.jobNumber}</td>
                        <td>{el.title}</td>
                        <td>{el.description}</td>
                        <td>{customerFullName(el.customer)}</td>
                        <td>{el.status}</td>{" "}
                        <td>
                          {el?.jobBudget && el?.budgetRequestid
                            ? currency(el?.jobBudget.amount, {
                                symbol: currenciesOptions.find(
                                  curr =>
                                    curr.cc ===
                                    (el?.jobBudget?.currency
                                      ? el?.jobBudget?.currency
                                      : generalSettings?.prevailingCurrency)
                                )?.symbol,
                              }).format()
                            : "..."}
                        </td>{" "}
                        {isAdmin || isCashier ? (
                          <td>
                            {currency(el.jobCost, {
                              symbol: currenciesOptions.find(
                                curr =>
                                  curr.cc ===
                                  (el?.currency
                                    ? el?.currency
                                    : generalSettings?.prevailingCurrency)
                              )?.symbol,
                            }).format()}
                          </td>
                        ) : null}
                        <td className="text-nowrap">
                          {el.dateLog ? format(new Date(el.dateLog), "dd MMM yyyy") : "..."}
                        </td>
                        <td>{el.username}</td>
                        <td>{el?.Job_Acquisition_Source || "..."}</td>
                        <td>{el?.Job_Progress_Status || "..."}</td>
                        <td className="text-nowrap">
                          {el.Contract_Award_Date
                            ? formatDate(new Date(el.Contract_Award_Date), "dd MMM yyyy")
                            : "..."}
                        </td>
                        <td className="text-nowrap">
                          {el.Expected_Completion_Date
                            ? formatDate(new Date(el.Expected_Completion_Date), "dd MMM yyyy")
                            : "..."}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {!isFetching && isSuccess && isEmpty(data?.jobs) ? (
                  <NoTableItem queryParams={queryParams} />
                ) : null}
              </div>

              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={e => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="50">50 rows</option>
                    <option value="100">100 rows</option> <option value="500">500 rows</option>{" "}
                    <option value="1000">1000 rows</option>{" "}
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(data.count / queryParams.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>

              <div className="summaryNew ">
                <div className="gridCont">
                  {hasReconcilation && (
                    <>
                      <div className="gridChild gridChildBorderLeftDarkerGreen">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data?.reconciledData?.reconciledData, {
                              symbol: "",
                            }).format()}
                          </p>
                          <p className="gridChld2">Reconciled</p>
                        </div>
                      </div>

                      <div className="gridChild gridChildBorderLeftOrange">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data?.reconciledData?.nonReconciledData, {
                              symbol: "",
                            }).format()}
                          </p>
                          <p className="gridChld2">Not Reconciled</p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalLoader show={isfetchingExcel || deleteMutation.isLoading || isLoading} />
        {selectedJob && (
          <UploadCompletionCertificate
            selectedJob={selectedJob}
            setSelectedJob={setSelectedJob}
            refetch={refetch}
            systemMilestones={data?.milestones}
          />
        )}

        {selectedJobForMileStone && (
          <MileStoneModal
            selectedJob={selectedJobForMileStone}
            setSelectedJob={setSelectedJobForMileStone}
            refetch={refetch}
            systemMilestones={data?.milestones}
          />
        )}

        {selectedJobForItems && (
          <JobItemsModal
            selectedJob={selectedJobForItems}
            setSelectedJob={setSelectedJobForItems}
            refetch={refetch}
            systemMilestones={data?.milestones}
          />
        )}

        {selectedJobForBudget && (
          <NewRequisitionModal
            modalTitle="Send for Approval"
            dataForPendingPermit={{}}
            defaultFormValues={{
              title: `Budget for ${selectedJobForBudget.title}`,
              description: "",
              type: "Budget",
              cost: "",
              vendor: "  ",
              jobNumber: selectedJobForBudget.jobNumber,
              department: authUser.Department,
              recipient: authUser.Staff_ID,
              jobGrade: authUser.jobGrade,
            }}
            selectedJob={{
              ...selectedJobForBudget,
              label: selectedJobForBudget.title,
              value: selectedJobForBudget.jobNumber,
            }}
            setShowNewRequisitionModal={setSelectedJobForBudget}
            handleCreatedRequisition={() => refetch()}
          />
        )}
      </main>
    </IsPrivileged>
  );
}

function UploadCompletionCertificate({
  selectedJob,
  setSelectedJob,
  refetch,
  systemMilestones = [],
}) {
  const { backendUrl } = useAuth();
  const formik = useFormik({
    initialValues: {
      files: [],
      jobId: selectedJob.id,
    },
    validationSchema: yup.object().shape({}),
    onSubmit: values => {
      // console.log(values, "jdj");
      uploadCertificateMutation.mutate(values);
    },
  });

  const uploadCertificate = async payload => {
    const formData = new FormData();
    payload.files = [
      //  old files
      ...(selectedJob?.files ? JSON.parse(selectedJob?.files) : []),
      ...payload.files,
    ];

    //   console.log(payload);
    for (const [key, value] of Object.entries(payload)) {
      if (key === "files") {
        for (const file of value) {
          if (!file?.fileId) {
            formData.append("files", file);
          }
        }
        continue;
      }
      formData.append(key, value);
    }
    /* formData.append(
      "alreadyUploadedFiles",
      JSON.stringify(payload.files.filter((el) => el?.fileId))
    ); */

    let response = await fetch(`${backendUrl}/api/job/upload-certificate`, {
      method: "POST",
      credentials: "include",
      body: formData,
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const uploadCertificateMutation = useMutation(payload => uploadCertificate(payload), {
    onSuccess: data => {
      toast.success("Success");
      refetch();
      setSelectedJob();
    },
    onError: err => {
      toast.error("Unable to Upload");
    },
  });

  return (
    <>
      <Modal
        show={true}
        animation={false}
        centered
        enforceFocus={false}
        onHide={() => setSelectedJob()}
      >
        <Modal.Header closeButton>
          <h2 className="h5">Upload Completion Certificate</h2>{" "}
        </Modal.Header>
        <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
          <Modal.Body>
            <div className="mb-4">
              <h3 className="h5">{selectedJob?.title}</h3>
              <p>{selectedJob?.description}</p>
            </div>

            {selectedJob.hasMilestone && (
              <Form.Group className={"mb-3"}>
                <Form.Label>Milestone</Form.Label>
                <CreatableSelect
                  classNamePrefix="form-select"
                  options={systemMilestones}
                  value={systemMilestones.find(el => el.value === formik.values.milestone)}
                  onChange={({ value }) => formik.setFieldValue("milestone", value)}
                  isSearchable
                />
              </Form.Group>
            )}

            <Form.Group>
              <Form.Label>Upload</Form.Label>
              <Attachments
                files={formik.values.files}
                setFiles={files => formik.setFieldValue("files", files)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" type="button" onClick={() => setSelectedJob()}>
              Cancel
            </Button>
            <Button type="submit">Upload</Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ModalLoader show={uploadCertificateMutation.isLoading} />
    </>
  );
}
