import CachedIcon from "mdi-react/CachedIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import {
  Button,
  Form,
  Table,
  Dropdown,
  Overlay,
  Popover,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  DeleteIcon,
  DropdownCheckIcon,
  DropdownCloseIcon,
  DropdownEyeIcon,
  DropdownMessageIcon,
  DropdownSendIcon,
  ExcelIcon,
  ExportIcon,
  FilterTwoIcon,
  LinkVariantIcon,
  PDFIcon,
  RecieptIcon,
  RefreshIcon,
  SearchIconV2,
  SummaryCloseIcon,
} from "../Icons";
import "./../../assets/scss/requisition/index.scss";
import ApprovalBadge from "./../../components/utils/ApprovalBadge";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { services } from "../../config";
import queryString from "query-string";
import { useState, useEffect, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import lodash, { flatMap, isEmpty, startCase, uniqBy, upperFirst } from "lodash";
import { format, parse } from "date-fns";
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ApproveAndCloseDialog, RejectDialog } from "./Dialog";
import { requisitionActions } from "../../utils/reactQueryActions";
import Avatar from "../utils/Avatar";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";
import {
  formatDate,
  formatNumberSystem,
  getUserThatRequestedToMe,
  isInventoryOrFundRequest,
  paginationOptions,
  resolveApprovalBadgeBg,
  searchParamsToObject,
  styleHelper,
} from "../../utils/helpers";
import { LinkWithQuery as Link } from "../utils/LinkWithQuery";
import useDebounce, {
  ScrollOnLocationChange,
  ScrollOnParamsChange,
  useIsAdmin,
  useIsOperations,
} from "../../utils/hooks";
import eventBus from "./../../utils/EventBus";
import { lowerCase } from "lodash";
import { last } from "lodash";
import NewRequisitionModal from "./NewRequisition";
import NewFundAndInventoryModal from "./NewFundAndInventoryModal";
import ReactPaginate from "react-paginate";
import ConfirmDialog from "../ConfirmDialogue";
import NoTableItem from "../utils/NoTableItem";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import Select from "react-select";
import currency from "currency.js";
import { symbol } from "prop-types";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import { CSVLink } from "react-csv";
import ModalLoader from "../utils/ModalLoader";
import { useStoreState } from "easy-peasy";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import { OverflowDropdown, StarCheckbox } from "../Component";
import { RequisitionLabelsPickerModal } from "./RequisitionLabels";
import LabelIcon from "mdi-react/LabelIcon";

const lf = new Intl.ListFormat("en");

const requisitionTypeOptions = [
  {
    label: "Sort by Type",
    value: "",
  },
  {
    label: "Material Request",
    value: "Material Request",
  },
  {
    label: "Fund Request",
    value: "Fund Request",
  },
  {
    label: "Budget",
    value: "Budget",
  },
  {
    label: "Sales Inventory Request",
    value: "Inventory",
  },
  {
    label: "Pending Service Invoice",
    value: "Pending Service Invoice",
  },
  {
    label: "Invoice (Vendor)",
    value: "Invoice (Vendor)",
  },
  {
    label: "Allowance",
    value: "Allowance",
  },
  {
    label: "Other",
    value: "Other",
  },
  {
    label: "Retirement for Fund Request",
    value: "Retirement for Fund Request",
  },
  {
    label: "User Action",
    value: "User Action",
  },
  {
    label: "Salary",
    value: "Salary",
  },
  {
    label: "User Action (Delete Vendor)",
    value: "User Action (Delete Vendor)",
  },
];

const sortByArchive = [
  {
    label: "Show All",
    value: "Show All",
  },
  {
    label: "Archived",
    value: "Archived",
  },
  {
    label: "Not Archived",
    Value: "Not Archived",
  },
];

export default function Request() {
  const { user: authUser, isPersonalAccount, backendUrl } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();
  const isOperations = useIsOperations();
  const [showNewRequisitionModal, setShowNewRequisitionModal] = useState(false);
  const [showAddLabelModal, setShowAddLabelModal] = useState(false);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);

  // fetch excel hook
  const [excelData, setExcelData] = useState([]);
  const CSVLinkRef = useRef(null);
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(excelData, CSVLinkRef);

  const locationQ = useLocation();

  const query = new URLSearchParams(locationQ.search);
  const globalValue = query.get("global");

  useEffect(() => {
    if (globalValue) {
      setShowNewRequisitionModal(true);
    }
  }, [globalValue]);

  /*  useEffect(() => {
     // Add the style when the component is mounted
     if (!styleHelper.isMobile)
       document.body.style.overflow = "hidden";

     // Remove the style when the component is unmounted
     return () => {
       if (!styleHelper.isMobile)
         document.body.style.overflow = "";
     };
   }, []); */

  let url = new URL(document.location.href);
  const generalSettings = useStoreState(state => state.generalSettings);

  url = searchParamsToObject(url.searchParams.entries());
  const requiredQueryParams = {
    page: 1,
    limit: 40,
    q: "",
    status: "",
    type: "",
    sortByDate: "dateModified",
    sortByArchive: generalSettings?.sortByArchieve ? generalSettings?.sortByArchieve : "",
    is: "",
    labelId: "",
    attachedTransactionID: "",
  };
  const initialQueryParams = !isEmpty(url)
    ? { ...requiredQueryParams, ...url }
    : requiredQueryParams;

  const [searchQuery, setSearchQuery] = useSearchParams(initialQueryParams);
  const [queryParams, setQueryParams] = useState(initialQueryParams);

  const debouncedQueryParams = useDebounce(queryParams, 800);
  useEffect(() => {
    setSearchQuery(debouncedQueryParams);
  }, [debouncedQueryParams]);

  const handleSearchQueryChange = e => {
    setQueryParams({
      // ...searchParamsToObject(searchQuery.entries()),
      ...queryParams,
      page: 1,
      [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  ScrollOnParamsChange(debouncedQueryParams);

  const getRequisition = async debouncedQueryParams => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/requisition?&${queryString.stringify(debouncedQueryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.allRequisitionTypeOptions = [
      ...requisitionTypeOptions,
      ...data.formsUsed
        .filter(el => el?.formUsed?.Title)
        .map(el => ({
          ...el,
          FormID: el.formUsed.id,
          label: el.formUsed.Title,
          value: el.formUsed.id,
        })),
    ];

    //  data.requisitions = []
    return data;
  };

  const {
    error,
    data = { count: 0, requisitions: [], allRequisitionTypeOptions: [] },
    refetch,
    isFetching,
  } = useQuery(
    [requisitionActions.GET_REQUISITION, debouncedQueryParams],
    () => getRequisition(debouncedQueryParams),
    {
      keepPreviousData: true,
      staleTime: 0,
      onSuccess: data => {},
    }
  );

  useEffect(() => {
    console.log(error);
  }, [error]);

  const reject = async (requisition, mainRequisition) => {
    await RejectDialog({
      authUser,
      requisition,
      requisitionTitle: mainRequisition.title,
      previousRequisitionTrackId: requisition.ID,
    });
  };

  const approveAndClose = async (requisition, mainRequisition) => {
    await ApproveAndCloseDialog({
      authUser,
      requisition,
      requisitionTitle: mainRequisition.title,
      previousRequisitionTrackId: requisition.ID,
    });
  };

  const handleRowClick = (e, el) => {
    return;
    if (e.detail === 2) {
      return navigate(
        el.type === "Normal" && isEmpty(el.filledFormData)
          ? `/requisition/request/details/${el.requestid}`
          : el.type === "Pending Service Invoice"
          ? `/sales-and-invoicing/edit-pending-service-invoice/${el.requestid.replace("REQ", "")}`
          : el.type === "Invoice (Vendor)"
          ? `/vendor/approve-vendors-invoice/${el.requestid}`
          : `/requisition/request/preview/${el.requestid}`
      );
    }
  };

  function onNewRequisitionTrack(updatedRequisition) {
    if (updatedRequisition) {
      queryClient.setQueryData([requisitionActions.GET_REQUISITION, debouncedQueryParams], data => {
        const requisitionIndex = data.requisitions.findIndex(
          el => el.requestid === updatedRequisition.requestid
        );
        data.requisitions[requisitionIndex] = updatedRequisition;
        return data;
      });

      queryClient.setQueryData(
        [requisitionActions.GET_REQUISITION_DETAILS, updatedRequisition.requestid],
        data => {
          data.requisition = updatedRequisition;
          return data;
        }
      );
    }
  }

  const onNewRequisitionCreated = requisition => {
    if (requisition.type === debouncedQueryParams.type || !debouncedQueryParams.type) {
      queryClient.setQueryData([requisitionActions.GET_REQUISITION, debouncedQueryParams], data => {
        data.requisitions = [requisition, ...data.requisitions];
        return data;
      });
    }
    copyLink(requisition);
  };

  eventBus.useCustomEventListener("REQUISITION_UPDATED", onNewRequisitionTrack);
  eventBus.useCustomEventListener("REQUISITION_CREATED", onNewRequisitionCreated);
  eventBus.useCustomEventListener("REQUISITION_FILTER_PARAMS", filterparams => {
    setQueryParams({
      ...queryParams,
      ...filterparams,
    });
  });

  const canPerformAction = requisition => {
    const pendingRequisition = requisition;

    // By Department
    if (
      pendingRequisition?.sentToDepartment === authUser.Department &&
      pendingRequisition?.sentby !== authUser.Staff_ID &&
      pendingRequisition?.status === "Pending"
    ) {
      return true;
    }

    return (
      requisition &&
      pendingRequisition.status === "Pending" &&
      pendingRequisition.receivedby === authUser.Staff_ID
    );
  };
  const handlePageChange = ({ selected }) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    setQueryParams({
      ...queryParams,
      page: selected + 1,
    });
  };

  const hrefBuilder = pageIndex => {
    let url = new URL(document.location.href);
    let params = new URLSearchParams(url.search);

    params.set("page", pageIndex);
    url.search = params.toString();

    return url.toString();
  };

  const copyLink = requisition => {
    const textarea = document.createElement("textarea");
    textarea.textContent = `${services.frontendUrl}/requisition/request/preview/${
      requisition.requestid
    }`;
    textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(textarea);

    toast.success("Link Copied");
  };

  const deleteRequisition = async payload => {
    let response = await fetch(`${backendUrl}/api/requisition/delete`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteMutation = useMutation(payload => deleteRequisition(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      refetch();
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const deleteReq = async requisition => {
    if (
      await ConfirmDialog({
        type: "danger",
        title: "Delete Requisition",
        description: "Are you sure, you want to delete this requisition?",
      })
    ) {
      deleteMutation.mutate(requisition);
    }
  };

  const filterByDateRange = date => {
    setQueryParams({
      ...queryParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setQueryParams({
      ...queryParams,
      startDate: "",
      endDate: "",
    });
  };

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/requisition?${queryString.stringify(rest)}`,
      "GET"
    );

    const { totalAmountNGN, totalAmountUSD } = exData.data;
    const company = exData.data?.company.CompName;
    const startIndex = exData.data?.startIndex;
    exData = exData.data.requisitions.map(row => ({
      ...row,
    }));

    exData = exData.map((d, index) => {
      const latestRequisition = last(d.requisitiontracks);
      return [
        startIndex + index + 1,
        formatNumberSystem(d?.numberPrefix, d?.currentNumber),
        d.title,
        d?.formUsed && d.formUsed?.Title ? d.formUsed?.Title : d.type,
        getUserThatRequestedToMe({
          requisitionTracks: d?.requisitiontracks,
          receivedby: authUser?.Staff_ID,
        }),
        format(new Date(d.regdate), "dd MMM, yyyy"),
        `${d.status} ${d.subStatus}`,
        d.type === "Fund Request"
          ? currency(d?.amount, {
              symbol: d.currency && d.currency === "USD" ? "$" : "₦",
            }).format()
          : "...",
        flatMap(d.requisitiontracks.map(d => [d.receivedbyUser, d.sentbyUser]))
          .filter(el => el && authUser.Staff_ID !== el.Staff_ID)
          .map(el => el?.Name)
          .join(", "),
      ];
    });
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company],
      ["Requests"],
      [date],
      [""],
      [
        "S/N",
        "Doc No.",
        "Requisition",
        "Type",
        "Request From",
        "Date",
        "Status",
        "Amount",
        "Approve with",
      ],
      ...exData,
      [""],
      ...(queryParams.type === "Fund Request"
        ? [
            ["Summary"],
            [
              "Total Amount (NGN)",
              currency(totalAmountNGN, {
                symbol: "",
              }).format(),
            ],
            [
              "Total Amount (USD)",
              currency(totalAmountUSD, {
                symbol: "$",
              }).format(),
            ],
          ]
        : []),
    ];
    //  console.log(exData);
    setExcelData(exData);
  };

  const favRequisition = async payload => {
    let response = await fetch(`${backendUrl}/api/requisition/favourite/${payload.requestid}`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const favMutation = useMutation(payload => favRequisition(payload), {
    onSuccess: ({ message }) => {
      // toast.success(message);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const handleFavourite = request => {
    // Optimistic changes before the API call
    queryClient.setQueryData([requisitionActions.GET_REQUISITION, debouncedQueryParams], data => {
      const requisitionIndex = data.requisitions.findIndex(
        el => el.requestid === request.requestid
      );
      const isFavourite =
        data.requisitions[requisitionIndex]?.Requisition_FavouriteRequisition?.isFavourite;
      data.requisitions[requisitionIndex] = {
        ...data.requisitions[requisitionIndex],
        Requisition_FavouriteRequisition: {
          ...(data.requisitions[requisitionIndex]?.Requisition_FavouriteRequisition
            ? data.requisitions[requisitionIndex]?.Requisition_FavouriteRequisition
            : {}),
          isFavourite: !Boolean(isFavourite),
        },
      };
      return data;
    });

    // Make API Cal
    favMutation.mutate(request);
  };

  const close = () => {
    setBtnSummaryActive(false);
  };

  return (
    <>
      <main className="cash-book">
        <div className="content">
          <header>
            <h1 className="d-flex align-items-center">
              {/*  <label htmlFor="queryParams-q" className="btn filter pt-2">
                <FilterTwoIcon />
              </label> */}
              <span>Requisition</span>{" "}
              {queryParams?.is && (
                <small className="text-light m-0">
                  &nbsp;&nbsp;{` / ${startCase(queryParams?.is)}` || ""}
                </small>
              )}
              {queryParams?.labelName && (
                <small className="text-light m-0">
                  &nbsp;&nbsp;{` / ${startCase(queryParams?.labelName)}` || ""}
                </small>
              )}
              {queryParams?.attachedTransactionID && (
                <small className="text-light m-0">
                  &nbsp;&nbsp;{` / ${startCase(queryParams?.attachedTransactionID)}` || ""}
                </small>
              )}
            </h1>

            <div className="requisition-control-area align-items-center p-0 w-50">
              <div className="search-area">
                <SearchIconV2 />
                <Form.Control
                  id="queryParams-q"
                  className=""
                  name="q"
                  value={queryParams.q}
                  onChange={e => handleSearchQueryChange(e)}
                  placeholder="Search..."
                />
              </div>
            </div>

            <div className="d-flex gap-3 align-items-center">
              {/*  <div>
                <Form.Select
                  name="sortByArchive"
                  value={queryParams.sortByArchive}
                  className="py-2"
                  onChange={(e) => handleSearchQueryChange(e)}
                >
                  {sortByArchive?.map((archive) => (
                    <option value={archive.value}>{archive.label}</option>
                  ))}
                </Form.Select>
              </div> */}

              <CSVLink
                className="btn print d-none"
                filename={`Requests(${format(new Date(), "dd-MMM-yyyy hh:mm:ss a")}).csv`}
                data={excelData}
                ref={CSVLinkRef}
              />
              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  className="btn print"
                  disabled={isfetchingExcel}
                  bsPrefix=""
                >
                  <ExportIcon color="#008000" />
                  Export
                </Dropdown.Toggle>
                <Dropdown.Menu
                  popperConfig={{
                    strategy: "fixed",
                  }}
                  renderOnMount
                  className="text-center"
                >
                  <Dropdown.Item as="div" onClick={onDownloadExcelData} className="p-cursor">
                    Excel <ExcelIcon color="#008000" />
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="a"
                    target="_blank"
                    href={`${backendUrl}/api/requisition/requests-pdf/requests?${queryString.stringify(
                      queryParams
                    )}`}
                    className="p-cursor"
                  >
                    PDF <PDFIcon color="#ff0000" />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <button
                onClick={() => setShowNewRequisitionModal(true)}
                className="btn btn-primary new"
              >
                + New Request
              </button>
            </div>
          </header>

          <div className="requisition-control-area align-items-center justify-content-end">
            {/*  <div className="search-area">
              <SearchIconV2 />
              <Form.Control
                id="queryParams-q"
                className=""
                name="q"
                value={queryParams.q}
                onChange={(e) => handleSearchQueryChange(e)}
                placeholder="Search..."
              />
            </div> */}

            <div className="d-flex gap-3 align-items-center">
              <RsDateRangePicker
                placement="bottomEnd"
                defaultValue={
                  queryParams.startDate && queryParams.endDate
                    ? [
                        parse(queryParams.startDate, "yyyy-MM-dd", new Date()),
                        parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                      ]
                    : []
                }
                onClean={() => clearDateRange()}
                onOk={date => filterByDateRange(date)}
              />

              <Form.Select
                name="sortByDate"
                value={queryParams.sortByDate}
                onChange={e => handleSearchQueryChange(e)}
              >
                <option value="">Sort by Date</option>
                <option value={"regdate"}>Date Created</option>
                <option value={"dateModified"}>Date modified</option>
              </Form.Select>
            </div>

            <Select
              placeholder="Sort by Type"
              className="col-md-2"
              classNamePrefix={"form-select"}
              menuPosition="fixed"
              menuPlacement="auto"
              options={data?.allRequisitionTypeOptions}
              isSearchable
              onChange={selected =>
                setQueryParams({
                  ...queryParams,
                  FormID: selected?.FormID || "",
                  type: selected?.value,
                })
              }
              value={data?.allRequisitionTypeOptions.find(el => el.value === queryParams.type)}
            />

            <Form.Select
              name="status"
              value={queryParams.status}
              onChange={e => handleSearchQueryChange(e)}
            >
              <option value="">Sort by Status</option>
              <option>Pending</option>
              <option>Approved & Sent</option>
              <option>Approved & Closed</option>
              <option>Disapproved</option>
              <option>Pending Retirement</option>
              <option>Retirement Confirmation</option>
              <option>Pending Payment</option>
              <option>Pending Reimbursement</option>
            </Form.Select>

            {/*  is Admin or is A Department lead   */}
            {isAdmin && !isPersonalAccount ? (
              <Form.Switch
                inline
                id="showAll"
                label="Show All"
                name="showAll"
                type={"checkbox"}
                checked={queryParams.showAll}
                onChange={e => handleSearchQueryChange(e)}
              />
            ) : null}

            {!isAdmin && data?.isDepartmentLead && (
              <Form.Switch
                inline
                id="showAllInDepartment"
                label="Show in Department"
                name="showAllInDepartment"
                type={"checkbox"}
                checked={queryParams.showAllInDepartment}
                onChange={e => handleSearchQueryChange(e)}
              />
            )}

            <Button
              onClick={() => refetch()}
              variant="outline-primary"
              className="border-0 rounded-4 p-2 d-flex align-items-center"
            >
              <RefreshIcon />
            </Button>

            <button
              onClick={() => {
                if (queryParams.type === "Fund Request") {
                  setBtnSummaryActive(!btnSummryActive);
                } else {
                  toast.info("Filter by Fund Request");
                }
              }}
              className="btn summaryBtn px-3"
            >
              Show Summary
            </button>
          </div>

          <div className="px-0 pt-0 border-top product-table-wrapper">
            <Table borderless responsive hover className="product-table">
              <thead className="bg-white" style={{ outline: "1px solid rgb(226, 232, 240)" }}>
                <tr>
                  <th />
                  {/* <th>S/N</th> */}
                  <th />
                  <th>Doc No.</th>
                  <th>Title</th>
                  <th>Type</th>
                  <th>Request From</th>
                  <th>Date {queryParams.sortByDate === "regdate" ? "Created" : "Modified"}</th>
                  <th>Status</th>
                  <th>Amount</th>
                  <th>Approve with</th>
                </tr>
              </thead>
              <tbody>
                {data.requisitions
                  ? data.requisitions.map((el, index) => {
                      const latestRequisition = last(el.requisitiontracks);
                      return (
                        <tr
                          key={el.requestid}
                          onClick={e => {
                            handleRowClick(e, el);
                          }}
                          className="p-cursor"
                        >
                          <td className="position-relative">
                            <div className="d-flex gap-2">
                              {/* <Link
                              to={
                                el.type === "Normal" &&
                                  isEmpty(el.filledFormData)
                                  ? `/requisition/request/details/${el.requestid
                                  }`
                                  : el.type === "Pending Service Invoice"
                                    ? `/sales-and-invoicing/edit-pending-service-invoice/${el.requestid.replace(
                                      "REQ",
                                      ""
                                    )}`
                                    : el.type === "Invoice (Vendor)"
                                      ? `/vendor/approve-vendors-invoice/${el.requestid
                                      }`
                                      : `/requisition/request/preview/${el.requestid
                                      }`
                              }
                              className="btn border bg-white text-primary d-flex align-items-center text-nowrap"
                              title="View Details"
                            >
                              <EyeOutlineIcon />
                            </Link> */}

                              <Dropdown style={{ margin: 0 }}>
                                <Dropdown.Toggle
                                  variant=""
                                  className="bg-light-blue text-primary"
                                  bsPrefix="print more"
                                >
                                  <DotsHorizontalIcon />
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  popperConfig={{
                                    strategy: "fixed",
                                  }}
                                  renderOnMount
                                  className="dropdown-with-icons"
                                >
                                  <Dropdown.Item as="div">
                                    <Link
                                      to={
                                        el.type === "Normal" && isEmpty(el.filledFormData)
                                          ? `/requisition/request/details/${el.requestid}`
                                          : el.type === "Pending Service Invoice"
                                          ? `/sales-and-invoicing/edit-pending-service-invoice/${el.requestid.replace(
                                              "REQ",
                                              ""
                                            )}`
                                          : el.type === "Invoice (Vendor)"
                                          ? `/vendor/approve-vendors-invoice/${el.requestid}`
                                          : `/requisition/request/preview/${el.requestid}`
                                      }
                                    >
                                      <DropdownEyeIcon /> Preview Requisition
                                    </Link>
                                  </Dropdown.Item>
                                  {el.jobNumber && (isAdmin || isOperations) ? (
                                    <Dropdown.Item as="div">
                                      <Link to={`/operations/jobs/details/${el.jobNumber}`}>
                                        <DropdownEyeIcon /> View Job Details
                                      </Link>
                                    </Dropdown.Item>
                                  ) : null}

                                  {/*   <Dropdown.Item as="div">
                                    <Link
                                      to={`/requisition/request/conversations/${
                                        el.requestid
                                      }`}
                                    >
                                      <DropdownMessageIcon /> Send Message
                                    </Link>
                                  </Dropdown.Item> */}

                                  <Dropdown.Item
                                    as="button"
                                    className=""
                                    onClick={() => setShowAddLabelModal(el)}
                                  >
                                    <LabelIcon /> Label as
                                  </Dropdown.Item>

                                  {canPerformAction(latestRequisition) &&
                                  isEmpty(el.filledFormData) &&
                                  !isInventoryOrFundRequest(lowerCase(el.type)) ? (
                                    <>
                                      <Dropdown.Item
                                        as="button"
                                        onClick={() => approveAndClose(latestRequisition, el)}
                                      >
                                        <DropdownCheckIcon /> Appove & Close
                                      </Dropdown.Item>
                                      <Dropdown.Item as="div">
                                        <Link
                                          to={`/requisition/request/approve-and-send/${
                                            el.requestid
                                          }`}
                                        >
                                          <DropdownSendIcon /> Appove & Sent
                                        </Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        as="button"
                                        className="text-danger"
                                        onClick={() => reject(latestRequisition, el)}
                                      >
                                        <DropdownCloseIcon /> Reject Requisition
                                      </Dropdown.Item>
                                    </>
                                  ) : null}

                                  <Dropdown.Item as="button" onClick={() => copyLink(el)}>
                                    <LinkVariantIcon />
                                    Copy Link
                                  </Dropdown.Item>

                                  {(el.requestby === authUser.Staff_ID ||
                                    (isAdmin && !isPersonalAccount)) &&
                                  !el?.status.includes("Approved & Closed") ? (
                                    <Dropdown.Item
                                      as="button"
                                      className="text-danger"
                                      onClick={() => deleteReq(el)}
                                    >
                                      <DeleteIcon /> Delete
                                    </Dropdown.Item>
                                  ) : null}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                          {/*  <td>
                          <span className="text-light">
                            {data.startIndex + index + 1}
                          </span>
                        </td> */}
                          <td>
                            <StarCheckbox
                              checked={el?.Requisition_FavouriteRequisition?.isFavourite}
                              setChecked={() => handleFavourite(el)}
                            />
                          </td>
                          <td className="text-nowrap">
                            {formatNumberSystem(el?.numberPrefix, el?.currentNumber)}
                          </td>
                          <td>
                            <span className="fw-6">
                              {el.type === "PO" && <p>{el?.POId || ""}</p>}
                              {el.title}
                            </span>
                          </td>
                          <td>
                            {el?.formUsed && el.formUsed?.Title ? el.formUsed?.Title : el.type}
                          </td>
                          <td>
                            {getUserThatRequestedToMe({
                              requisitionTracks: el.requisitiontracks,
                              receivedby: authUser?.Staff_ID,
                            }) || el?.sentByName}
                          </td>
                          <td>
                            {queryParams.sortByDate === "regdate" ? (
                              <>
                                {formatDate(el.regdate)}{" "}
                                {el?.createdTimestamp && formatDate(el.createdTimestamp, "hh:mm a")}
                              </>
                            ) : el?.dateModified ? (
                              <>{formatDate(el?.dateModified, "dd MMM, yyyy \n hh:mm a")}</>
                            ) : (
                              <>
                                {formatDate(el.regdate, "dd MMM, yyyy")}{" "}
                                {el?.createdTimestamp && formatDate(el.createdTimestamp, "hh:mm a")}
                              </>
                            )}
                          </td>
                          <td>
                            <span
                              className="p-cursor"
                              onClick={() =>
                                navigate(`/requisition/request/details/${el.requestid}`)
                              }
                            >
                              <ApprovalBadge
                                text={el?.status}
                                className="approval"
                                bg={resolveApprovalBadgeBg(el?.status)}
                              />

                              {el?.subStatus && (
                                <ApprovalBadge
                                  text={el.subStatus}
                                  className="approval"
                                  bg={resolveApprovalBadgeBg(el.subStatus)}
                                />
                              )}
                            </span>
                          </td>
                          <td>
                            {el.type === "Fund Request" || el?.amount != 0
                              ? currency(el?.amount, {
                                  symbol: !el.currency ? "" : el?.currency === "USD" ? "$" : "₦",
                                }).format()
                              : "..."}
                          </td>
                          <td>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id={index}>
                                  {" "}
                                  {lf.format(
                                    uniqBy(
                                      flatMap(
                                        el.requisitiontracks.map(el => [
                                          el.receivedbyUser,
                                          el.sentbyUser,
                                        ])
                                      ).filter(el => el && authUser.Staff_ID !== el.Staff_ID),
                                      "Staff_ID"
                                    ).map((el, index, arr) =>
                                      el?.Name ? el.Name : el?.sentToDepartment
                                    )
                                  )}
                                </Tooltip>
                              }
                            >
                              <div className="d-flex gap-2 align-items-center ">
                                <div className="avatar-list d-flex">
                                  {uniqBy(
                                    flatMap(
                                      el.requisitiontracks.map(el => [
                                        el.receivedbyUser,
                                        el.sentbyUser,
                                      ])
                                    )
                                      .filter(el => el && authUser.Staff_ID !== el.Staff_ID)
                                      .slice(0, 2),
                                    "Staff_ID"
                                  ).map((el, index, arr) => (
                                    <Avatar
                                      key={el.Staff_ID}
                                      name={el?.Name ? el.Name : el?.sentToDepartment}
                                      style={{
                                        fontSize: "0.75rem",
                                      }}
                                      userId={el?.Staff_ID}
                                    />
                                  ))}
                                </div>
                                {latestRequisition.receivedbyUser?.Name ||
                                  latestRequisition?.receivedByName ||
                                  latestRequisition?.sentToDepartment ||
                                  "..."}
                              </div>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>

            {isEmpty(data.requisitions) && !isFetching ? (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <NoTableItem queryParams={{}} />
              </div>
            ) : null}

            <div className="d-flex justify-content-between px-3 align-items-center pagination">
              {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
              <div className="pagination_left">
                <p className="m-0 p-0">Show</p>
                <select
                  value={queryParams.limit}
                  name="limit"
                  className="form-select "
                  onChange={e => handleSearchQueryChange(e)}
                >
                  <option value="10">10 rows</option>
                  <option value="20">20 rows</option>
                  <option value="30">30 rows</option>
                  <option value="40">40 rows</option>
                  <option value="50">50 rows</option>
                  <option value="100">100 rows</option>{" "}
                </select>
              </div>

              <ReactPaginate
                {...paginationOptions}
                hrefBuilder={hrefBuilder}
                pageCount={data.count / queryParams.limit}
                marginPagesDisplayed={2}
                pageRangeDisplayed={0}
                onPageChange={handlePageChange}
                forcePage={queryParams.page - 1}
              />
            </div>
          </div>

          {queryParams.type === "Fund Request" && (
            <div
              className={btnSummryActive ? `summaryNew border-top open` : `summaryNew border-top`}
            >
              <div className="summaryHeader">
                <h2>Summary</h2>
                {btnSummryActive && (
                  <button onClick={() => close()} className="btn p-0">
                    <SummaryCloseIcon />
                  </button>
                )}
              </div>

              <div className="gridCont">
                <div className="gridChild gridChildBorderLeftDarkGreen">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.totalAmountNGN, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Amount (NGN)</p>
                  </div>
                </div>
                <div className="gridChild gridChildBorderLeftBlue">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.totalAmountUSD, {
                        symbol: "$",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Amount (USD)</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <div className="pagination ">

              <div className="pagination_left">
                <p className="m-0 p-0">Show</p>
                <select name="rows" className="form-select ">
                  <option value="10">10 rows</option>
                  <option value="20">20 rows</option>
                  <option value="30">30 rows</option>
                  <option value="40">40 rows</option>
                </select>
              </div>


              <div className="pagintion_right">
                <Pagination>
                  <Pagination.Item active>{1}</Pagination.Item>
                  <Pagination.Item>{2}</Pagination.Item>

                  <Pagination.Ellipsis className="border-none" />

                  <Pagination.Item>{6}</Pagination.Item>
                  <Pagination.Next className="next" />
                </Pagination>
              </div>
            </div>*/}
        </div>
      </main>

      <ModalLoader title="Downloading Excel..." show={isfetchingExcel} />

      {showNewRequisitionModal && (
        <NewRequisitionModal setShowNewRequisitionModal={setShowNewRequisitionModal} />
      )}

      {showAddLabelModal && (
        <RequisitionLabelsPickerModal
          show={showAddLabelModal}
          setShow={setShowAddLabelModal}
          selectedData={showAddLabelModal}
        />
      )}

      <Outlet />
    </>
  );
}
