import React, { useEffect, useCallback } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useQuery } from "react-query";
import { useAuth } from "../../hooks/useAuth";
import Select from "react-select";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import NumberCustomInput from "../utils/NumberCustomInput";
import { ErrorMessage, FieldArray, FormikProvider } from "formik";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import WarehouseItemTable from "../warehouse/WarehouseItemTable";
import currency from "currency.js";

export default function CustomLoadingOrderInput({
  formik,
  drivers,
  stage = [],
  isCreateInvoice = false,
  setEditedItemIndex,
}) {
  const { backendUrl, token } = useAuth();

  const getCustomer = async () => {
    let response = await fetch(
      // `${backendUrl}/api/customers?withCredit=${true}&withPaymentDue=${true}`,
      `${backendUrl}/api/vendors`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    data.vendors = data.vendors.map(el => ({
      ...el,
      label: el.CompanyName,
      value: el.Vendor_ID,
    }));
    return data;
  };
  const { data = { vendors: [] }, isFetching, isLoading } = useQuery(
    ["GETVENDORS"],
    () => getCustomer(),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  return (
    <div style={{ pointerEvents: isCreateInvoice ? "none" : "auto" }}>
      <FormikProvider value={formik}>
        <section className="date">
          <h2>Delivery Information</h2>

          <FieldArray
            name="deliveryInformation"
            render={arrayHelpers => (
              <>
                {formik.values.deliveryInformation?.map((el, index) => (
                  <div className="my-3 p-4 border">
                    <Form.Group className=" mb-3 d-flex justify-content-between">
                      <Form.Check
                        type="switch"
                        className="custom-form-check"
                        name={`deliveryInformation[${index}].InhouseLoading`}
                        checked={formik.values?.deliveryInformation[index]?.InhouseLoading}
                        label={"In-house Loading"}
                        onChange={e => {
                          formik.setFieldValue(
                            `deliveryInformation[${index}].InhouseLoading`,
                            e.target.checked
                          );

                          formik.setFieldValue(`deliveryInformation[${index}].DriverName`, "");
                          formik.setFieldValue(`deliveryInformation[${index}].EmployeeID`, "");

                          formik.setFieldValue(`deliveryInformation[${index}].Vendor_ID`, "");
                          formik.setFieldValue(`deliveryInformation[${index}].Vendor_Name`, "");
                        }}
                      />
                      <button
                        type="button"
                        title="Remove"
                        className=" btn-danger "
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        ✖
                      </button>
                    </Form.Group>

                    {!formik.values?.deliveryInformation[index]?.InhouseLoading && (
                      <Form.Group className="mb-3">
                        <Form.Label className="mb-1">Transport Company</Form.Label>

                        <Select
                          classNamePrefix={"form-select"}
                          className="customer-select-dropdown w-100"
                          data={data?.vendors}
                          menuPlacement="bottom"
                          menuPosition="fixed"
                          isSearchable={true}
                          isClearable={true}
                          value={data?.vendors.find(
                            el => el.value === formik.values?.deliveryInformation[index].Vendor_ID
                          )}
                          options={data?.vendors}
                          onChange={selection => {
                            formik.setFieldValue(
                              `deliveryInformation[${index}].Vendor_ID`,
                              selection?.Vendor_ID
                            );
                            formik.setFieldValue(
                              `deliveryInformation[${index}].Vendor_Name`,
                              selection?.label
                            );
                          }}
                          id={`deliveryInformation[${index}]?.Vendor_ID`}
                        />
                      </Form.Group>
                    )}

                    <Form.Group className="mb-3 pb-1">
                      <Form.Label className="mb-1">Driver's Name</Form.Label>
                      {!formik.values?.deliveryInformation[index]?.InhouseLoading ? (
                        <>
                          <Form.Control
                            type="text"
                            placeholder="Enter Driver's Name"
                            name={`deliveryInformation[${index}].DriverName`}
                            value={formik.values.deliveryInformation[index]?.DriverName}
                            onChange={e => {
                              formik.setFieldValue(
                                `deliveryInformation[${index}].DriverName`,
                                e.target.value
                              );
                            }}
                            // isInvalid={
                            //   formik.touched.deliveryInformation[index]
                            //     ?.DriverName &&
                            //   !!formik.errors.deliveryInformation[index]?.DriverName
                            // }
                          />
                        </>
                      ) : (
                        <Select
                          classNamePrefix={"form-select"}
                          className="customer-select-dropdown w-100"
                          data={drivers}
                          menuPlacement="bottom"
                          menuPosition="fixed"
                          isSearchable={true}
                          isClearable={true}
                          // value={drivers?.find(
                          //   (el) =>
                          //     el?.value ===
                          //     formik.values?.deliveryInformation[index]
                          //       ?.DriverName
                          // )}

                          value={{
                            value: formik.values?.deliveryInformation[index]?.DriverName,
                            label: formik.values?.deliveryInformation[index]?.DriverName,
                          }}
                          options={drivers}
                          onChange={selection => {
                            formik.setFieldValue(
                              `deliveryInformation[${index}].DriverName`,
                              selection?.value
                            );
                            formik.setFieldValue(
                              `deliveryInformation[${index}].EmployeeID`,
                              selection?.EmployeeID
                            );
                          }}
                          id={`deliveryInformation[${index}].DriverName`}
                        />
                      )}
                      <ErrorMessage
                        component="span"
                        className="text-danger mb-2"
                        name={`deliveryInformation[${index}].DriverName`}
                      />
                    </Form.Group>

                    {/* <Form.Group className="col mb-3 pb-1 ">
                      <Form.Label className="mb-1">Truck Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Truck Number"
                        name={`deliveryInformation[${index}].TruckNumber`}
                        value={
                          formik.values.deliveryInformation[index].TruckNumber
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            `deliveryInformation[${index}].TruckNumber`,
                            e.target.value
                          );
                        }}
                      />
                      <ErrorMessage
                        component="span"
                        className="text-danger mb-2"
                        name={`deliveryInformation[${index}].TruckNumber`}
                      />
                    </Form.Group> */}

                    <Form.Group className=" mb-3 pb-2">
                      <Form.Label className="mb-1">Truck Number</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Enter Truck Number"
                          name={`deliveryInformation[${index}].TruckNumber`}
                          value={formik.values.deliveryInformation[index].TruckNumber}
                          onChange={e => {
                            formik.setFieldValue(
                              `deliveryInformation[${index}].TruckNumber`,
                              e.target.value
                            );
                          }}
                        />

                        {formik.values?.deliveryInformation[index]?.InhouseLoading ? (
                          <Popover
                            isOpen={formik.values.deliveryInformation[index].showItemsPopover}
                            onClickOutside={() =>
                              formik.setFieldValue(
                                `deliveryInformation[${index}].showItemsPopover`,
                                false
                              )
                            }
                            content={() => (
                              <WarehouseItemTable
                                handleSelectedItem={item => {
                                  formik.setFieldValue(
                                    `deliveryInformation[${index}].showItemsPopover`,
                                    false
                                  );
                                  formik.setFieldValue(
                                    `deliveryInformation[${index}].TruckNumber`,
                                    item.Bar_Code
                                  );
                                }}
                                registerAsset={true}
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text
                              onClick={() =>
                                formik.setFieldValue(
                                  `deliveryInformation[${index}].showItemsPopover`,
                                  !formik.values.showItemsPopover
                                )
                              }
                            >
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>
                        ) : null}
                      </InputGroup>
                      <ErrorMessage
                        component="span"
                        className="text-danger mb-2"
                        name={`deliveryInformation[${index}].TruckNumber`}
                      />
                    </Form.Group>

                    <Form.Group className="col mb-3 pb-1 ">
                      <Form.Label className="mb-1">Agent</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Agent"
                        name={`deliveryInformation[${index}].Agent`}
                        value={formik.values.deliveryInformation[index].Agent}
                        onChange={e => {
                          formik.setFieldValue(
                            `deliveryInformation[${index}].Agent`,
                            e.target.value
                          );
                        }}
                      />
                      <ErrorMessage
                        component="span"
                        className="text-danger mb-2"
                        name={`deliveryInformation[${index}].Agent`}
                      />
                    </Form.Group>

                    <Form.Group className="col mb-3 pb-1 ">
                      <Form.Label className="mb-1">Seal Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Seal Number"
                        name={`deliveryInformation[${index}].SealNumber`}
                        value={formik.values.deliveryInformation[index].SealNumber}
                        onChange={e => {
                          formik.setFieldValue(
                            `deliveryInformation[${index}].SealNumber`,
                            e.target.value
                          );
                        }}
                      />
                      <ErrorMessage
                        component="span"
                        className="text-danger mb-2"
                        name={`deliveryInformation[${index}].SealNumber`}
                      />
                    </Form.Group>

                    <div className="row">
                      <Form.Group className="mb-3 pb-1 col-md-6">
                        <Form.Label className="mb-1">Service Charge Per Litre</Form.Label>

                        <CurrencyCustomInput
                          id={`deliveryInformation[${index}].ServiceChargePerLitre`}
                          name={`deliveryInformation[${index}].ServiceChargePerLitre`}
                          placeholder="0.00"
                          defaultValue=""
                          value={formik.values.deliveryInformation[index]?.ServiceChargePerLitre}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(
                              `deliveryInformation[${index}].ServiceChargePerLitre`,
                              value
                            );
                            const total =
                              Number(value) *
                              Number(formik.values.deliveryInformation[index]?.Volume);

                            formik.setFieldValue(`deliveryInformation[${index}].Total`, total);
                          }}
                          // disabled={formik.values.duePaymentTransactionID}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3 pb-1  col-md-6">
                        <Form.Label className="mb-1">Volume Allocated</Form.Label>
                        <NumberCustomInput
                          placeholder="0"
                          value={formik.values.deliveryInformation[index]?.Volume}
                          name={`deliveryInformation[${index}].Volume`}
                          //   value={
                          //     selectedData.find(
                          //       (id) => id.TruckNumber === el.TruckNumber
                          //     )?.QtyCounted
                          //   }
                          onValueChange={(value, name) => {
                            formik.setFieldValue(`deliveryInformation[${index}].Volume`, value);

                            const total =
                              Number(value) *
                              Number(
                                formik.values.deliveryInformation[index]?.ServiceChargePerLitre
                              );

                            formik.setFieldValue(`deliveryInformation[${index}].Total`, total);

                            // formik.setFieldValue(
                            //   `deliveryInformation[${index}].VolumeLoaded`,
                            //   value
                            // );
                          }}
                        />{" "}
                      </Form.Group>
                    </div>
                    <Form.Group className="mb-3 pb-1 w-100">
                      <Form.Label className="mb-1">Shipping Cost</Form.Label>
                      <CurrencyCustomInput
                        id={`newInstallment`}
                        name={`deliveryInformation[${index}].Total`}
                        placeholder="0.00"
                        defaultValue=""
                        value={formik.values.deliveryInformation[index]?.Total}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);
                        }}
                        disabled={`deliveryInformation[${index}].Total`}
                      />
                    </Form.Group>
                    <div className=" row">
                      {["edit pending", "on transit", "delivered", "disabled"].includes(
                        stage[0]
                      ) && (
                        <Form.Group className="mb-3 pb-1  col-md-6">
                          <Form.Label className="mb-1">Volume Loaded</Form.Label>
                          <NumberCustomInput
                            placeholder="0"
                            value={formik.values.deliveryInformation[index]?.VolumeLoaded}
                            name={`deliveryInformation[${index}].VolumeLoaded`}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(
                                `deliveryInformation[${index}].VolumeLoaded`,
                                value
                              );

                              if (
                                ["edit pending", "on transit", "delivered", "disabled"].includes(
                                  stage[2]
                                )
                              ) {
                                const shortage =
                                  Number(value) -
                                  Number(formik.values.deliveryInformation[index]?.VolumeSupplied);

                                formik.setFieldValue(
                                  `deliveryInformation[${index}].ShortageVolume`,
                                  Number(shortage)
                                );
                              }
                            }}
                            disabled={[
                              "edit pending",
                              "on transit",
                              "delivered",
                              "disabled",
                            ].includes(stage[3])}
                          />{" "}
                        </Form.Group>
                      )}

                      {["edit pending", "on transit", "delivered", "disabled"].includes(
                        stage[1]
                      ) && (
                        <Form.Group className="mb-3 pb-1  col-md-6">
                          <Form.Label className="mb-1">Volume Supplied</Form.Label>
                          <Form.Control
                            onClick={() => setEditedItemIndex(0)}
                            value={
                              currency(formik.values.deliveryInformation[index]?.VolumeSupplied, {
                                symbol: "",
                              }).value
                            }
                            disabled={[
                              "edit pending",
                              "on transit",
                              "delivered",
                              "disabled",
                            ].includes(stage[3])}
                            readOnly
                            className="p-cursor"
                          />

                          {/*   <NumberCustomInput
                              placeholder="0"

                              value={
                                formik.values.deliveryInformation[index]
                                  ?.VolumeSupplied
                              }
                              name={`deliveryInformation[${index}].VolumeSupplied`}
                              onValueChange={(value, name) => {
                                formik.setFieldValue(
                                  `deliveryInformation[${index}].VolumeSupplied`,
                                  value
                                );

                                if (
                                  [
                                    "edit pending",
                                    "on transit",
                                    "delivered",
                                    "disabled",
                                  ].includes(stage[2])
                                ) {
                                  const shortage =
                                    Number(
                                      formik.values.deliveryInformation[index]
                                        ?.VolumeLoaded
                                    ) - Number(value);
                                  formik.setFieldValue(
                                    `deliveryInformation[${index}].ShortageVolume`,
                                    Number(shortage))
                                }
                              }}
                              disabled={[
                                "edit pending",
                                "on transit",
                                "delivered",
                                "disabled",
                              ].includes(stage[3])}
                            />{" "} */}
                        </Form.Group>
                      )}
                    </div>

                    {["edit pending", "on transit", "delivered", "disabled"].includes(stage[2]) && (
                      <Form.Group className="mb-3 pb-1  ">
                        <Form.Label className="mb-1">Shortage Volume</Form.Label>
                        <NumberCustomInput
                          placeholder="0"
                          value={formik.values.deliveryInformation[index]?.ShortageVolume}
                          name={`deliveryInformation[${index}].ShortageVolume`}
                          disabled={`deliveryInformation[${index}].ShortageVolume`}
                        />{" "}
                      </Form.Group>
                    )}
                  </div>
                ))}

                <div>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary text-nowrap"
                    onClick={() =>
                      arrayHelpers.push({
                        Vendor_ID: "",
                        Vendor_Name: "",
                        ServiceChargePerLitre: 0,
                        Volume: 0,
                        Total: 0,
                        DriverName: "",
                        TruckNumber: "",
                      })
                    }
                  >
                    + Add
                  </button>
                </div>
              </>
            )}
          />
        </section>
      </FormikProvider>
      <hr />
    </div>
  );
}
